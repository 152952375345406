
import dynamic from "next/dynamic";
import Link from "next/link";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { useRouter, usePathname } from 'next/navigation';

import StickyContainer from "components/StickyContainer";
import UserLoading from "../HeaderUser/Loading";
import Feedback from "../HeaderFeedback";
import logExternalLinkClick from 'lib/ga/externalLinkClick';
import logInternalLinkClick from "lib/ga/internalLinkClick";
import logEvent from "lib/ga/miscEvent";
import AuthService from "_api/AuthService";
import { useEffect, useState, useContext } from "react";
import { modalSet } from "_slices/view";
import { userLogOut } from 'context/reducers/user'
import { Context } from 'context/context';
import styles from './header.module.css';

const User = dynamic(() => import("../HeaderUser"), {
  loading: () => <UserLoading />,
  ssr: true,
});

const ModalProvider = dynamic(() => import("components/Modal/ModalProvider"), {
  ssr: false,
});



const SiteNav = ({ isApplicationWrapper = false, extraWidth = false }) => {
  const { cookieConsentGiven } = useSelector((state: RootStateOrAny) => state.auth);
  let dispatchRedux = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const router = useRouter();
  const [isProductListOpen, setIsProductListOpen] = useState(false);
  const [isAdvertiseListOpen, setIsAdvertiseListOpen] = useState(false);
  const [isAccountListOpen, setIsAccountListOpen] = useState(false);

  const { dispatch, state } = useContext(Context);
  const pathname = usePathname();
  const [loggedIn, setIsLoggedIn] = useState(state.user.loggedIn);
  useEffect(() => {
    setIsLoggedIn(state.user.loggedIn)
  }, [state.user])

  const transparent = pathname === '/';
  const handleMobileMenu = () => [
    setIsActive(current => !current)
  ]
  const handleProductList = () => [
    setIsProductListOpen(current => !current)
  ]
  const handleAdvertiseList = () => {
    console.log('hello9')
    setIsAdvertiseListOpen(!isAdvertiseListOpen)
  }
  const handleAccountList = () => [
    setIsAccountListOpen(current => !current)
  ]

  const checkScrolled = () => {
    setScrolled(window.scrollY > 50);
  };
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    checkScrolled();
    window.addEventListener("scroll", checkScrolled);
    window.addEventListener("resize", checkScrolled);

    return function cleanup() {
      window.removeEventListener("scroll", checkScrolled);
      window.removeEventListener("resize", checkScrolled);
    };
  });

  const handleLogOut = (e) => {
    e.preventDefault();

    logEvent({
      category: "Login",
      action: "Logged out",
    });

    handleMobileMenu()
    userLogOut(dispatch)
    if (pathname.includes('profile')) {
      router.push('/')
    }
  };

  return (
    <div className={`${styles.Container} ${transparent ? styles.homeContainer : ''}`} id="header">
      <AuthService />
      <ModalProvider />
      <StickyContainer offsetFromTop={0} reactToValues={[cookieConsentGiven]}>
        <div className={styles.bar} style={{ backgroundColor: transparent && !scrolled ? "transparent" : 'rgb(29,37,44)' }}>
          <nav>
            <ul className={extraWidth ? `${styles.extraWidth} ${styles.InnerUl}` : styles.InnerUl}>
              <li className={styles.logoLink}>
                <a href="/" onClick={() => logInternalLinkClick("Navbar Logo", "/")}>
                  <picture>
                    <img
                      src={pathname === '/profile/dx-search' ? `/icons/completelyRetailBeta.svg` : `${process.env.NEXT_PUBLIC_APP_CDN_URL}icons/CR-logo-reduced.svg`}
                      height="50"
                      width="430"
                      alt="Completely Retail: The UK's no.1 place to find shops to rent"
                    />
                  </picture>
                </a>
              </li>
              <li className={styles.middleNav}>
                <div className={styles.NavContainer}>
                  <Link href="/shops-for-sale" onClick={() => logInternalLinkClick("Navbar buy", "/shops-for-sale")} className={pathname === '/shops-for-sale' ? `${styles.link} ${styles.active}` : styles.link} passHref prefetch={false} >
                    Buy
                  </Link>
                  <Link href="/shops-to-rent" className={pathname === '/shops-to-rent' ? `${styles.link} ${styles.active}` : styles.link} onClick={() => logInternalLinkClick("Navbar Rent", "/shops-to-rent")} passHref prefetch={false} >
                    Rent
                  </Link>
                  <Link href="/portfolio" className={pathname === '/portfolio' ? `${styles.link} ${styles.active}` : styles.link} onClick={() => logInternalLinkClick("Navbar Portfolios", "/portfolio")} passHref prefetch={false} >
                    Portfolios
                  </Link>
                  <div className={styles.advertise}>
                    <p className={['/agent-pricing', '/landlord-pricing', '/sales/bids', '/sales/advertise', '/sales/contact'].includes(pathname) ? `${styles.link} ${styles.active} ${styles.advertiseLink}` : `${styles.link} ${styles.advertiseLink}`} >
                      Advertise
                    </p>
                    <div className={`${styles.advertiseDropdown} ${styles.hide}`}>
                      <div className={`${styles.advertiseInner} ${styles.advertiseArrow}`}>
                        <Link href="/agent-pricing" passHref prefetch={false} onClick={() => logInternalLinkClick("Navbar For Agents", "/agent-pricing")} >
                          For Agents
                        </Link>
                        <Link href="/landlord-pricing" passHref prefetch={false} onClick={() => logInternalLinkClick("Navbar For Landlords", "/landlord-pricing")} >
                          For Landlords
                        </Link>
                        <Link href="/sales/bids" passHref prefetch={false} onClick={() => logInternalLinkClick("Navbar For Councils", "/sales/bids")} >
                          For Councils
                        </Link>
                        <Link href="/data-exchange" passHref prefetch={false} onClick={() => logInternalLinkClick("Navbar Data Exchange", "/data-exchange")} >
                          Data Exchange
                        </Link>
                        <Link href="/sales/advertise" passHref prefetch={false} onClick={() => logInternalLinkClick("Navbar Brand Advertising", "/sales/advertise")} >
                          Brand Advertising
                        </Link>
                      </div>

                    </div>
                  </div>
                  <Link href="/contact" className={styles.link} passHref prefetch={false} >
                    Contact us
                  </Link>
                </div>
              </li>
              <li>
                <User loggedIn={loggedIn} />
                <div onClick={handleMobileMenu} className={!isActive ? styles.mobileMenuOpenButton : `${styles.mobileMenuOpenButton} ${styles.hideMobile}`}>
                  <div></div>
                  <div></div>
                </div>

                <div onClick={handleMobileMenu} className={isActive ? styles.mobileMenuCloseButton : `${styles.mobileMenuCloseButton} ${styles.hideMobile}`}>
                  x
                </div>
              </li>
            </ul>
          </nav>
        </div>
        <div className={isActive ? styles.InnerMobile : `${styles.hideMobile} ${styles.InnerMobile}`}>
          <ul>
            <li className={styles.mainBullet}>
              <Link href="/shops-for-sale" passHref prefetch={false} onClick={() => { logInternalLinkClick("Navbar Buy", "/shops-for-sale"); handleMobileMenu() }} >
                Buy
              </Link>
            </li>
            <li className={styles.mainBullet}>
              <Link href="/shops-to-rent" passHref prefetch={false} onClick={() => { logInternalLinkClick("Navbar rent", "/shops-to-rent"); handleMobileMenu() }} >
                Rent
              </Link>
            </li>
            <li className={styles.mainBullet}>
              <Link href="/portfolios" passHref prefetch={false} onClick={() => { logInternalLinkClick("Navbar portfolio", "/portfolios"); handleMobileMenu() }} >
                Portfolios
              </Link>
            </li>

            <li className={`${styles.mainBullet} ${styles.advertise}`} >
              <p onClick={() => { handleAdvertiseList() }}>Advertise</p>
              <ul className={isAdvertiseListOpen ? styles.show : styles.hideMobile}>
                <li>
                  <Link href="/agent-pricing" passHref prefetch={false} onClick={() => { logInternalLinkClick("Navbar For Agents", "/agent-pricing"); }} >
                    For Agents
                  </Link>
                </li>
                <li>
                  <Link href="/landlord-pricing" passHref prefetch={false} onClick={() => { logInternalLinkClick("Navbar For Landlords", "/landlord-pricing"); }} >
                    For Landlords
                  </Link>
                </li>
                <li>
                  <Link href="/sales/bids" passHref prefetch={false} onClick={() => { logInternalLinkClick("Navbar For Councils", "/sales/bids"); }} >
                    For Councils
                  </Link>
                </li>
                <li>
                  <Link href="/data-exchange" passHref prefetch={false} onClick={() => logInternalLinkClick("Navbar Data Exchange", "/data-exchange")} >
                    Data Exchange
                  </Link>
                </li>
                <li>
                  <Link href="/sales/advertise" passHref prefetch={false} onClick={() => { logInternalLinkClick("Navbar Brand Advertising", "/agent-pricing"); }} >
                    Brand Advertising
                  </Link>
                </li>
              </ul>
            </li>

            {!loggedIn && (
              <>
                <li className={styles.mainBullet} onClick={(e) => {
                  dispatchRedux(modalSet({ visible: true, type: "auth", view: "login", router }))
                  handleMobileMenu()
                }}
                >Sign In</li>
                <li className={styles.mainBullet} onClick={(e) => {
                  dispatchRedux(modalSet({ visible: true, type: "auth", view: "register", router }))
                  handleMobileMenu()
                }}>
                  Register</li>
              </>
            )}

            {loggedIn && (
              <>
                <li className={styles.mainBullet}>
                  <Link href="/profile" passHref prefetch={false} onClick={() => { logInternalLinkClick("Navbar For my account", "/profile"); handleMobileMenu() }} >
                    My account
                  </Link>
                </li>
                <li className={styles.mainBullet} onClick={(e) => handleLogOut(e)}>Logout</li>
              </>
            )}
          </ul>
        </div>
        <Feedback />
      </StickyContainer>
    </div>
  );
};


export default SiteNav;
